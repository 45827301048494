import { requests } from "../../../api/agent";

export default {
  getOrgs: (params) => requests.getWithParams("/reOrg/ListAllPaged", params),
  getOrgByName: (params) => requests.getWithParams("/reOrg/getByName", params),
  getReOrgByUNP: (params) =>
    requests.getWithParams("/reOrg/getReOrgByUNP", params),
  getReOrgByThemesOrSubThemes: (params) =>
    requests.getWithParams("/reOrg/getReOrgByThemesOrSubThemes", params),
  getReOrgById: (params) =>
    requests.getWithParams("/reOrg/getReOrgById", params),
  getMinReOrgByNameOrUNP: (params) =>
    requests.getWithParams("/reOrg/getMinReOrgByNameOrUNP", params),
  CheckReOrgIsRegistered: (params) =>
    requests.getWithParams("/reOrg/checkReOrgIsRegistered", params),
  getTypesStatuses: () => requests.get("/reOrg/getTypesStatuses"),
  createReOrg: (params) => requests.post("/reOrg/create", params),
  deleteReOrg: (params) => requests.post("/reOrg/delete", params),
  updateReOrg: (params) => requests.post("/reOrg/update", params),
  CheckReOrgIsAvailable: (params) =>
    requests.post("/reOrg/CheckReOrgIsAvailable", params),
};
